/**
 * start vue in performance mode
 *
 * @example
 *
 * ```bash
 * VITE_CONFIG_PERFORMANCE=true npm run serve
 * ```
 */
export const VITE_CONFIG_PERFORMANCE = import.meta.env.VITE_CONFIG_PERFORMANCE;

/**
 * if active extra routes will be activated
 * useful for local development
 *
 * @example
 *
 * ```bash
 * VITE_COMMON_DEV=true npm run serve
 * ```
 */
export const VITE_COMMON_DEV = import.meta.env.VITE_COMMON_DEV;

export const VITE_COMMON_SLOTS = import.meta.env.VITE_COMMON_SLOTS;
