import { DefinedSideNavConfig, defineSideNav } from "@otto-ec/ottoapi-portal-common/navigation";
import { profiles, reference } from "@otto-ec/ottoapi-portal-api-reference/navigation";
import { sideNav as guidelinesSideNav } from "@otto-ec/ottoapi-portal-guidelines";

export default defineSideNav({
  "REST API": {
    "GETTING STARTED": {
      "Introduction": {
        public: true,
        file: () => import("./rest-api/index.md"),
        path: "/rest/getting-started/introduction",
      },
      "Getting access": {
        public: true,
        file: () => import("./rest-api/getting-started/getting-access.md"),
        path: "/rest/getting-started/getting-access",
      },
      "Scopes": {
        public: true,
        file: () => import("./rest-api/getting-started/scopes.md"),
        path: "/rest/getting-started/scopes",
      },
      "Authentication": {
        public: true,
        file: () => import("./rest-api/getting-started/authentication.md"),
        path: "/rest/getting-started/authentication",
      },
    },
    "ABOUT THE API": {
      "API operations": {
        public: true,
        file: () => import("./rest-api/about-the-api/api-operations.md"),
        path: "/rest/about-the-api/api-operations",
      },
      "HTTP methods": {
        public: true,
        file: () => import("./rest-api/about-the-api/http-methods.md"),
        path: "/rest/about-the-api/http-methods",
      },
      "Status codes": {
        public: true,
        file: () => import("./rest-api/about-the-api/status-codes.md"),
        path: "/rest/about-the-api/status-codes",
      },
      "Error responses": {
        public: true,
        file: () => import("./rest-api/about-the-api/error-responses.md"),
        path: "/rest/about-the-api/error-responses",
        "ValidationError": {
          public: true,
          path: "/errors/ValidationError",
          redirectHash: `#validation-failed-problem-detail-object`,
          redirectToParent: true,
        },
        "validation-failed": {
          public: true,
          path: "/problems/validation-failed",
          redirectHash: `#validation-failed-problem-detail-object`,
          redirectToParent: true,
        },
      },
      "Headers": {
        public: true,
        file: () => import("./rest-api/about-the-api/headers.md"),
        path: "/rest/about-the-api/headers",
      },
      "Pagination": {
        public: true,
        file: () => import("./rest-api/about-the-api/pagination.md"),
        path: "/rest/about-the-api/pagination",
      },
      "Hypermedia": {
        public: true,
        file: () => import("./rest-api/about-the-api/hypermedia.md"),
        path: "/rest/about-the-api/hypermedia",
      },
      "Versioning": {
        public: true,
        file: () => import("./rest-api/about-the-api/versioning.md"),
        path: "/rest/about-the-api/versioning",
      },
      "Preview": {
        public: true,
        file: () => import("./rest-api/about-the-api/preview.md"),
        path: "/rest/about-the-api/preview",
      },
      "Deprecation": {
        public: true,
        file: () => import("./rest-api/about-the-api/deprecation.md"),
        path: "/rest/about-the-api/deprecation",
      },
    },
    "API REFERENCE": {
      "Benefits": {
        file: () => import("./rest-api/api-reference/benefits.md"),
        path: "/reference/benefits",
        ...reference(["benefits"]),
      },
      "Checkout": {
        public: true,
        file: () => import("./rest-api/api-reference/checkout.md"),
        path: "/reference/checkout",
        ...reference(["checkout"]),
      },
      "Customer": {
        public: true,
        file: () => import("./rest-api/api-reference/customer.md"),
        path: "/reference/customer",
        ...reference(["userinfo"]),
      },
      "Customer account": {
        file: () => import("./rest-api/api-reference/customer-account.md"),
        path: "/reference/customer-account",
        ...reference(["abused-accounts", "credentials"]),
      },
      "Data protection": {
        file: () => import("./rest-api/api-reference/data-protection.md"),
        path: "/reference/dataProtection",
        ...reference(["gdpr"]),
      },
      "Deals": {
        file: () => import("./rest-api/api-reference/deals.md"),
        path: "/reference/deals",
        ...reference(["deal"]),
      },
      "Delivery": {
        file: () => import("./rest-api/api-reference/delivery.md"),
        path: "/reference/delivery",
        ...reference(["delivery"]),
      },
      "Discoverability": {
        file: () => import("./rest-api/api-reference/discoverability.md"),
        path: "/reference/discoverability",
        ...reference(["ranking", "navigation", "selection"]),
      },
      "Email & Push": {
        file: () => import("./rest-api/api-reference/email-push.md"),
        path: "/reference/email-push",
        ...reference(["mailstatus", "doubleoptin", "hash", "permissions", "email-verification"]),
      },
      "Loyalty rewards": {
        public: true,
        file: () => import("./rest-api/api-reference/loyalty-rewards.md"),
        path: "/reference/loyaltyRewards",
        ...reference(["up-bm"]),
      },
      "Orders": {
        public: true,
        file: () => import("./rest-api/api-reference/orders.md"),
        path: "/reference/orders",
        ...reference(["order-positions", "order-position-items"]),
      },
      "Payment": {
        file: () => import("./rest-api/api-reference/payment.md"),
        path: "/reference/payment",
        ...reference(["payment", "invoices"]),
      },
      "Product data": {
        public: true,
        file: () => import("./rest-api/api-reference/product-data.md"),
        path: "/reference/productData",
        ...reference(["product"]),
      },
      "Tracking": {
        file: () => import("./rest-api/api-reference/tracking.md"),
        path: "/reference/tracking",
        ...reference(["tracking"]),
      },
      "Transactional communication": {
        public: true,
        file: () => import("./rest-api/api-reference/transactional-communication.md"),
        path: "/reference/transactionalCommunication",
        ...reference(["transactional-communication"]),
      },
      "User feedback": {
        public: true,
        file: () => import("./rest-api/api-reference/user-feedback.md"),
        path: "/reference/userFeedback",
        ...reference(["user-feedback"]),
      },
      "Warranty": {
        file: () => import("./rest-api/api-reference/warranty.md"),
        path: "/reference/warranty",
        ...reference(["download", "revoke"]),
      },
      "Wishlist": {
        file: () => import("./rest-api/api-reference/wishlist.md"),
        path: "/reference/wishlist",
        ...reference(["wishlist"]),
      },
    },
    "RESOURCE REPRESENTATION": {
      "Link relations": {
        public: true,
        file: () => import("./rest-api/resource-representation/link-relations.md"),
        path: "/link-relations",
      },
      "Profiles": {
        public: true,
        file: () => import("./rest-api/resource-representation/profiles.md"),
        ...profiles(),
      },
    },
    "REVISION HISTORY": {
      "Changelog": {
        public: true,
        file: () => import("../build/changelog.api.md"),
        path: "/rest/changelog",
      },
    },
  },
  ...(guidelinesSideNav as DefinedSideNavConfig),
  "API Gateway": {
    "ABOUT THE GATEWAY": {
      "Introduction": {
        file: () => import("./gateway/about-the-gateway/introduction.md"),
        path: "/gateway/about-the-gateway/introduction",
      },
      "Scope of service": {
        file: () => import("./gateway/about-the-gateway/scope-of-service.md"),
        path: "/gateway/about-the-gateway/scope-of-service",
      },
    },
    "BACKEND INTEGRATION": {
      "Environments": {
        file: () => import("./gateway/backend-integration/environments.md"),
        path: "/gateway/backend-integration/environments",
      },
      "Requirements": {
        file: () => import("./gateway/backend-integration/requirements.md"),
        path: "/gateway/backend-integration/requirements",
      },
      "Preparation": {
        file: () => import("./gateway/backend-integration/preparation.md"),
        path: "/gateway/backend-integration/preparation",
      },
      "Integration": {
        file: () => import("./gateway/backend-integration/integration.md"),
        path: "/gateway/backend-integration/integration",
      },
    },
    "SECURITY": {
      "Logging and monitoring": {
        file: () => import("./gateway/security/logging-and-monitoring.md"),
        path: "/gateway/security/logging-and-monitoring",
      },
      "Resilience": {
        file: () => import("./gateway/security/resilience.md"),
        path: "/gateway/security/resilience",
      },
      "Infrastructure security": {
        file: () => import("./gateway/security/infrastructure-security.md"),
        path: "/gateway/security/infrastructure-security",
      },
      "Data protection": {
        file: () => import("./gateway/security/data-protection.md"),
        path: "/gateway/security/data-protection",
      },
    },
    "FAQ": {
      "Topics": {
        file: () => import("./gateway/faq/topics.md"),
        path: "/gateway/security/topics",
      },
    },
  },
  "Support": {
    "FAQ": {
      "Topics": {
        public: true,
        file: () => import("./support/faq.md"),
        path: "/support/faq/topics",
      },
    },
    "GLOSSARY": {
      "A-D": {
        public: true,
        file: () => import("./support/glossary/0010_a-d.md"),
        path: "/support/glossary/a-d",
      },
      "E-H": {
        public: true,
        file: () => import("./support/glossary/0020_e-h.md"),
        path: "/support/glossary/e-h",
      },
      "I-L": {
        public: true,
        file: () => import("./support/glossary/0030_i-l.md"),
        path: "/support/glossary/i-l",
      },
      "M-P": {
        public: true,
        file: () => import("./support/glossary/0040_m-p.md"),
        path: "/support/glossary/m-p",
      },
      "Q-T": {
        public: true,
        file: () => import("./support/glossary/0050_q-t.md"),
        path: "/support/glossary/q-t",
      },
      "U-Z": {
        public: true,
        file: () => import("./support/glossary/0060_u-z.md"),
        path: "/support/glossary/u-z",
      },
    },
    "CONTACT": {
      "Get in touch": {
        public: true,
        file: () => import("./support/contact.md"),
        path: "/support/contact/get-in-touch",
      },
    },
  },
  "Wiki": {
    "PRODUCT MANAGEMENT": {
      "How to make APIs a part of your product management work": {
        file: () => import("./wiki/product-management/how-to-make-apis-a-part-of-your-product-management-work.md"),
        path: "/wiki/product-management/how-to-make-apis-a-part-of-your-product-management-work",
      },
    },
    "OPENAPI SPECIFICATION": {
      "How to create a good OpenAPI contract": {
        file: () => import("./wiki/openapi-specification/how-to-create-a-good-openapi-contract.md"),
        path: "/wiki/openapi-specification/how-to-create-a-good-openapi-contract",
      },
      "Custom extensions": {
        file: () => import("./wiki/openapi-specification/custom-extensions.md"),
        path: "/wiki/openapi-specification/custom-extensions",
      },
      "Designing OpenAPI specs to be published on the API Portal": {
        file: () => import("./wiki/openapi-specification/designing-openapi-specs-to-be-published-on-the-api-portal.md"),
        path: "/wiki/openapi-specification/designing-openapi-specs-to-be-published-on-the-api-portal",
      },
      "Check your API for guideline compliance": {
        file: () => import("./wiki/openapi-specification/check-your-api-for-guideline-compliance.md"),
        path: "/wiki/openapi-specification/check-your-api-for-guideline-compliance",
      },
      "Flagging an API with preview": {
        file: () => import("./wiki/openapi-specification/flagging-an-api-with-preview.md"),
        path: "/wiki/openapi-specification/flagging-an-api-with-preview",
      },
      "About link relations and profiles": {
        file: () => import("./wiki/openapi-specification/about-link-relations-and-profiles.md"),
        path: "/wiki/openapi-specification/about-link-relations-and-profiles",
      },
      "Using profiles and link relations": {
        file: () => import("./wiki/openapi-specification/using-profiles-and-link-relations.md"),
        path: "/wiki/openapi-specification/using-profiles-and-link-relations",
      },
      "Checklist: Designing an API contract": {
        file: () => import("./wiki/openapi-specification/checklist-designing-an-api-contract.md"),
        path: "/wiki/openapi-specification/checklist-designing-an-api-contract",
      },
      "Checklist: Reviewing an API specification": {
        file: () => import("./wiki/openapi-specification/checklist-reviewing-an-api-specification.md"),
        path: "/wiki/openapi-specification/checklist-reviewing-an-api-specification",
      },
      "What to consider when deprecating APIs": {
        file: () => import("./wiki/openapi-specification/deprecation/what-to-consider-when-deprecating-apis.md"),
        path: "/wiki/openapi-specification/what-to-consider-when-deprecating-apis",
      },
      "Checklist: Deprecating APIs": {
        file: () => import("./wiki/openapi-specification/deprecation/checklist-deprecating-apis.md"),
        path: "/wiki/openapi-specification/checklist-deprecating-apis",
      },
      "Define schemas in OpenAPI": {
        file: () => import("./wiki/openapi-specification/best-practices/define-schemas-in-openapi.md"),
        path: "/wiki/openapi-specification/define-schemas-in-openapi",
      },
      "Embed sub-resources in a response schema": {
        file: () => import("./wiki/openapi-specification/best-practices/embed-sub-resources-in-a-response-schema.md"),
        path: "/wiki/openapi-specification/embed-sub-resources-in-a-response-schema",
      },
      "Reuse, inherit, override": {
        file: () => import("./wiki/openapi-specification/best-practices/reuse-inherit-override.md"),
        path: "/wiki/openapi-specification/reuse-inherit-override",
      },
    },
    "API PORTAL": {
      "Publishing an API on the API Portal": {
        file: () => import("./wiki/api-portal/publishing-an-api-on-the-api-portal.md"),
        path: "/wiki/api-portal/publishing-an-api-on-the-api-portal",
      },
      "Versioning of OpenAPI spec files": {
        file: () => import("./wiki/api-portal/versioning-of-openapi-spec-files.md"),
        path: "/wiki/api-portal/versioning-of-openapi-spec-files",
      },
      "Supported parameter serialization method": {
        file: () => import("./wiki/api-portal/supported-parameter-serialization-method.md"),
        path: "/wiki/api-portal/supported-parameter-serialization-method",
      },
      "Checklist: Handling spec files for the API Portal": {
        file: () => import("./wiki/api-portal/checklist-handling-spec-files-for-the-api-portal.md"),
        path: "/wiki/api-portal/checklist-handling-spec-files-for-the-api-portal",
      },
      "Checklist: Evaluation for adding an API to the API Portal": {
        file: () => import("./wiki/api-portal/checklist-evaluation-for-adding-an-api-to-the-api-portal.md"),
        path: "/wiki/api-portal/checklist-evaluation-for-adding-an-api-to-the-api-portal",
      },
    },
    "API IMPLEMENTATION": {
      "Content-type-based versioning with Spring Boot": {
        file: () => import("./wiki/api-implementation/content-type-based-versioning-with-spring-boot.md"),
        path: "/wiki/api-implementation/content-type-based-versioning-with-spring-boot",
      },
    },
    "API GATEWAY": {
      "Register an API in the gateway": {
        file: () => import("./wiki/api-gateway/register-an-api-in-the-gateway.md"),
        path: "/wiki/api-gateway/register-an-api-in-the-gateway",
      },
      "OAuth 2.0 clients and scopes": {
        file: () => import("./wiki/api-gateway/oauth-clients-and-scopes.md"),
        path: "/wiki/api-gateway/oauth-clients-and-scopes",
      },
    },
    "FAQ": {
      "Topics": {
        file: () => import("./wiki/faq/topics.md"),
        path: "/wiki/faq/topics",
      },
    },
  },
  "Legal": {
    "TERMS AND CONDITIONS": {
      "OTTO API": {
        public: true,
        file: () => import("./legal/api_terms-and-conditions.md"),
        path: "/terms-and-conditions",
      },
      "API Portal": {
        public: true,
        file: () => import("./legal/portal_terms-and-conditions.md"),
        path: "/terms-and-conditions/api-portal",
      },
    },
  },
  "DATA PROTECTION": {
    "DATA PROTECTION": {
      public: true,
      file: () => import("./legal/data-protection.md"),
      path: "/data-protection",
    },
  },
});
