<script lang="ts" setup>
import { computed } from "vue";
import { marked, Renderer } from "marked";

const props = defineProps<{ markdown: string; deprecated?: boolean }>();

const renderer = new Renderer();
renderer.paragraph = (text) => `<p class="pl_copy100">${text}</p>`;
renderer.link = (href, title, text) => `<a class="pl_link--primary" href="${href}">${text}</a>`;
renderer.list = (body, ordered) =>
  ordered
    ? `<ol class="pl_list--ordered">${body}</ol>`
    : `<ul class="pl_list--unordered">${body}</ul>`;
renderer.codespan = (code) => `<code>${code}</code>`;
const html = computed(() =>
  props.markdown
    ? marked(props.markdown, { renderer, headerIds: false, mangle: false })
    : undefined,
);
</script>

<template>
  <div class="markdown" :class="{ 'markdown--deprecated': deprecated }" v-html="html"></div>
</template>

<style lang="scss" scoped>
.markdown {
  &--deprecated {
    opacity: 0.45;
  }
}
</style>
